import React from "react";
import { Table } from "antd";

const TxTable = ({ loading, dataSource, onTxClick }) => {
  const columns = [
    {
      title: "Tx hash",
      dataIndex: "hash_id",
      render: (value) => (
        <a
          href={"/"}
          onClick={(e) => {
            e.preventDefault();
            onTxClick?.(value);
          }}
        >
          {value}
        </a>
      ),
    },
    { title: "Height", dataIndex: "height" },
    { title: "Transaction type", dataIndex: "tx_type" },
  ];
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      rowKey={({ hash_id }) => hash_id}
      loading={loading}
    />
  );
};

export default TxTable;
