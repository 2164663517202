import React from "react";
import { TypeAnimation } from "react-type-animation";

const Logo = () => {
  return (
    <div className={"logo"}>
      <TypeAnimation
        cursor={false}
        sequence={[100, "NAMADA_SE EXPLORER BY HEDONISM_TEAM"]}
        speed={70}
      />
    </div>
  );
};
export default Logo;
