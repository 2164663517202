export const DTF = "DD.MM.YYYY HH:mm:ss UTC";

export const PAGE = {
  OVERVIEW: "Overview",
  VAL: "Validators",
  BLOCKS: "Blocks",
  TXS: "Transactions",
  BLOCK: "Block Details",
  TX: "Transaction Details",
};
