import React from "react";
import { Skeleton } from "antd";

const LoadingCard = ({ label, value, loading, onClick }) => {
  return (
    <div className={"loading-card"} key={label}>
      <label>{label}</label>
      {loading ? (
        <Skeleton title={false} paragraph={{ rows: 1 }} loading />
      ) : onClick ? (
        <a
          onClick={(e) => {
            e.preventDefault();
            onClick?.(value);
          }}
          href={"/"}
        >
          <span>{value}</span>
        </a>
      ) : (
        <span>{value}</span>
      )}
    </div>
  );
};

export default LoadingCard;
