import React, { useState, useEffect } from "react";
import { Table } from "antd";
import api from "./../api";

const Validators = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .getValidators()
      .then(setDataSource)
      .finally(() => setLoading(false));
  }, []);

  const columns = [
    { title: "Moniker", dataIndex: "moniker" },
    { title: "Tokens", dataIndex: "tokens" },
    {
      title: "Voting Power, %",
      dataIndex: "voting_power_percent",
      render: (value) => Math.floor(value * 100) / 100,
    },
    {
      title: "Rank",
      dataIndex: "rank",
    },
  ];
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      loading={loading}
      rowKey={({ rank }) => rank}
    />
  );
};

export default Validators;
