import React, { useState, useEffect } from "react";
import api from "./../api";
import TxTable from "../components/TxTable";

const Txs = ({ onTxClick }) => {
  const [dataSource, setDataSource] = useState([]);
  const [currentBlock, setCurrentBlock] = useState(0);
  const [loading, setLoading] = useState(true);

  const getBlockLast = async () => {
    const blockLastResponse = await api.getBlockLast();
    const height = blockLastResponse?.header?.height;
    if (height) {
      setCurrentBlock(height);
    }
  };

  const getBlockTransactions = async (height) => {
    const blockDetailsResponse = await api.getBlockDetails(height);
    const { tx_hashes } = blockDetailsResponse || {};
    if (tx_hashes) {
      setDataSource((prev) => [
        ...prev,
        ...tx_hashes.map((item) => ({ height, ...item })),
      ]);
      setCurrentBlock((prev) => (prev > 1 ? prev - 1 : prev));
    }
  };

  useEffect(() => {
    if (currentBlock > 0 && dataSource.length < 100) {
      getBlockTransactions(currentBlock).then();
    }
  }, [currentBlock, dataSource.length]);

  useEffect(() => {
    getBlockLast().then(() => setLoading(false));
  }, []);

  return (
    <TxTable dataSource={dataSource} loading={loading} onTxClick={onTxClick} />
  );
};

export default Txs;
