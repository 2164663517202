import React, { useState, useEffect } from "react";
import api from "./../api";
import LoadingCard from "../components/LoadingCard";
import * as changeCase from "change-case";
import MessageBlock from "../components/MessageBlock";

const Tx = ({ id }) => {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!id) return;

    api.getTransactionDetails(id).then((response) => {
      setLoading(false);
      if (response?.Error) {
        setErrorMessage("Not found");
        return;
      }

      setDetails(response);
    });
  }, [id]);

  if (errorMessage) {
    return <MessageBlock message={errorMessage} />;
  }

  return (
    <div className={"loading-cards one-column"}>
      {Object.entries(details || {})
        .reduce((acc, [key, value]) => {
          if (
            value &&
            (typeof value === "string" || typeof value === "number")
          ) {
            if (typeof value === "string" && Number(value) === 0) {
              return acc;
            }

            return [{ value, label: changeCase.capitalCase(key) }, ...acc];
          }
          return acc;
        }, [])
        .map((item) => ({ ...item, loading }))
        .map(LoadingCard)}
    </div>
  );
};

export default Tx;
