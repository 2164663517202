import React, { useState, useEffect } from "react";
import api from "./../api";
import dayjs from "dayjs";
import LoadingCard from "../components/LoadingCard";
import { DTF } from "../constants";
import TxTable from "../components/TxTable";
import MessageBlock from "../components/MessageBlock";

const Block = ({ id, onTxClick }) => {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    api.getBlockDetails(id).then(async (blockDetails) => {
      if (!blockDetails?.header) {
        setErrorMessage("Not found");
        return;
      }
      setDetails(blockDetails);
      setLoading(false);
    });
  }, [id]);

  if (errorMessage) {
    return <MessageBlock message={errorMessage} />;
  }
  const {
    header: { height, time = new Date(), proposer_address } = {},
    tx_hashes = [],
  } = details || {};

  return (
    <>
      <div className={"loading-cards"}>
        {[
          { label: "Height", value: height },
          { label: "Time", value: dayjs(time).format(DTF) },
          { label: "Number of txs", value: tx_hashes.length },
          { label: "Proposer", value: proposer_address },
        ]
          .map((item) => ({ ...item, loading }))
          .map(LoadingCard)}
      </div>
      <div className={"table-block"}>
        <h3>Transactions</h3>
        <TxTable
          dataSource={tx_hashes.map((item) => ({ height, ...item }))}
          onTxClick={onTxClick}
        />
      </div>
    </>
  );
};

export default Block;
