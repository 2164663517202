import React, { useState } from "react";
import { Layout, Menu, Row, ConfigProvider } from "antd";
import "antd/dist/antd.variable.min.css";
import Txs from "./pages/Txs";
import Blocks from "./pages/Blocks";
import Validators from "./pages/Validators";
import Overview from "./pages/Overview";
import Block from "./pages/Block";
import Tx from "./pages/Tx";
import SearchControl from "./components/SearchControl";
import ParticleBackground from "react-particle-backgrounds";

import { PAGE } from "./constants";
import Logo from "./components/Logo";

const { Content, Sider, Header } = Layout;

ConfigProvider.config({
  theme: {
    primaryColor: "darkgrey",
  },
});

function App() {
  const [page, setPage] = useState(PAGE.OVERVIEW);
  const [input, setInput] = useState("");

  const onSelectBlock = (blockId) => {
    setInput(blockId);
    setPage(PAGE.BLOCK);
  };

  const onSelectTx = (tx) => {
    setInput(tx);
    setPage(PAGE.TX);
  };

  return (
    <Layout className="app-layout">
      <Header className="header">
        <ParticleBackground
          className={"ParticleBackground"}
          settings={{
            particle: {
              particleCount: 30,
              color: "black",
              minSize: 1,
              maxSize: 4,
            },
            velocity: {
              minSpeed: 0.1,
              maxSpeed: 0.2,
            },
            opacity: {
              minOpacity: 0,
              maxOpacity: 0.5,
              opacityTransitionTime: 10000,
            },
          }}
        />
        <Logo />
        <SearchControl
          onSubmit={({ page, value }) => {
            console.log(page);
            setPage(page);
            setInput(value);
          }}
        />
      </Header>
      <Layout>
        <Sider>
          <Menu
            selectedKeys={[page]}
            mode={"inline"}
            onSelect={({ key }) => {
              setPage(key);
            }}
            items={[PAGE.OVERVIEW, PAGE.VAL, PAGE.BLOCKS, PAGE.TXS].map(
              (key) => ({
                key,
                label: key,
              }),
            )}
          />
        </Sider>
        <Content>
          <Row className={"page-title"}>
            <h2>{page}</h2>
          </Row>
          {page === PAGE.TXS ? (
            <Txs onTxClick={onSelectTx} />
          ) : page === PAGE.BLOCKS ? (
            <Blocks onBlockClick={onSelectBlock} />
          ) : page === PAGE.VAL ? (
            <Validators />
          ) : page === PAGE.OVERVIEW ? (
            <Overview onBlockClick={onSelectBlock} />
          ) : page === PAGE.BLOCK ? (
            <Block id={input} onTxClick={onSelectTx} />
          ) : page === PAGE.TX ? (
            <Tx id={input} />
          ) : null}
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;
