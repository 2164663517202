import React, { useState, useEffect } from "react";
import api from "./../api";
import dayjs from "dayjs";
import LoadingCard from "../components/LoadingCard";
import { DTF } from "../constants";

let interval = null;

const Overview = ({ onBlockClick }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = () => api.getBlockLast().then(setData);

    getData().then(() => setLoading(false));

    interval = setInterval(getData, 8000);

    return () => clearInterval(interval);
  }, []);

  const { height, time = new Date(), chain_id } = data?.header || {};

  return (
    <div className={"loading-cards"}>
      {[
        { label: "Latest Block", value: height, onClick: onBlockClick },
        { label: "Block Time", value: dayjs(time).format(DTF) },
        { label: "Chain", value: chain_id },
      ]
        .map((item) => ({ ...item, loading }))
        .map(LoadingCard)}
    </div>
  );
};

export default Overview;
