const SERVER_API = {
  NG: `https://namada.api.explorers.guru/api/`, // API Nodes Guru
  IDX: `https://namadexer.hedonismteam.com/`, // Наш indexer на QUAY
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getBlocks: (height) => get({ url: "blocks", count: 40, height }),
  getValidators: () =>
    get({ url: "validators" }).then(({ validators }) => validators),
  getBlockLast: () => get({ server: SERVER_API.IDX, url: "block/last" }),
  getBlockDetails: (id) =>
    get({ server: SERVER_API.IDX, url: "block/height/" + id }),
  getTransactionDetails: (id) =>
    get({ server: SERVER_API.IDX, url: "tx/" + id }),
};

export const get = async ({ server = SERVER_API.NG, url = "", ...data }) => {
  const params = Object.entries(data);

  try {
    const response = await fetch(
      server +
        url +
        (params.length
          ? "?" + params.map(([param, value]) => `${param}=${value}`).join("&")
          : ""),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      },
    );

    return await response.json();
  } catch (e) {
    console.log(e);
  }
};
