import React, { useState, useEffect } from "react";
import { Table } from "antd";
import api from "./../api";

const Blocks = ({ onBlockClick }) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.getBlockLast().then((resp) => {
      const height = resp?.header?.height;
      if (height) {
        api
          .getBlocks(height)
          .then(setDataSource)
          .finally(() => setLoading(false));
      }
    });
  }, []);

  const columns = [
    {
      title: "Block Height",
      dataIndex: "height",
      render: (value) => (
        <a
          href={"/"}
          onClick={(e) => {
            e.preventDefault();
            onBlockClick?.(value);
          }}
        >
          {value}
        </a>
      ),
    },
    { title: "Hash", dataIndex: "hash" },
    { title: "Time", dataIndex: "time" },
    {
      title: "Moniker",
      dataIndex: ["proposer", "moniker"],
    },
  ];
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      loading={loading}
      rowKey={({ hash }) => hash}
    />
  );
};

export default Blocks;
