import React, { useState } from "react";
import { Input } from "antd";
import { PAGE } from "../constants";

const { Search } = Input;

const SearchControl = ({ onSubmit }) => {
  const [input, setInput] = useState("");

  const onSearch = (value) => {
    const height = Number(value);
    if (height > 0) {
      onSubmit({ page: PAGE.BLOCK, value: height });
    } else {
      onSubmit({ page: PAGE.TX, value });
    }
  };

  return (
    <Search
      className={"search"}
      value={input}
      placeholder="tx height / block height"
      allowClear
      onChange={(e) => setInput(e.target.value)}
      onSearch={onSearch}
    />
  );
};

export default SearchControl;
